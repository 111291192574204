import { AuthService } from '@/services/auth';
import { getAccessToken, getRefreshToken, isValidJwt, logout, parseJwt } from '@/utils/authority';
import { handleError, wait } from '@/utils/utils';

const JWT_EXPIRY_THRESHOLD = 30000;
let refreshPromise: Promise<string> | undefined;
let lastAutoRefresh: number | undefined;

const doRefreshToken = () => {
  const doRefreshTokenWithRetry = async (refreshToken: string) => {
    let result;
    for (let i = 0; i < 5; i++) {
      if (i !== 0) {
        await wait(5000);
      }

      const accessToken = getAccessToken();
      if (accessToken && isValidJwt(accessToken, JWT_EXPIRY_THRESHOLD * 2)) {
        return accessToken;
      }

      try {
        return (await AuthService.refreshToken({ refreshToken })).accessToken;
      } catch (error) {
        result = error;
      }
    }
    throw result;
  };

  const refreshToken = getRefreshToken();
  if (!refreshToken || !isValidJwt(refreshToken)) {
    logout();
    return Promise.reject('The refresh token is missing or expired');
  }

  if (refreshPromise) {
    return refreshPromise;
  }

  refreshPromise = navigator?.locks?.request
    ? navigator.locks.request('refresh_token', () => doRefreshTokenWithRetry(refreshToken))
    : doRefreshTokenWithRetry(refreshToken);
  refreshPromise.finally(() => {
    refreshPromise = undefined;
  });
  return refreshPromise;
};

const observeAccessToken = () => {
  const getJwtReminderTime = (jwt: string) => {
    const parsedJwt = parseJwt(jwt);
    return parsedJwt.exp * 1000 - JWT_EXPIRY_THRESHOLD - Date.now();
  };

  if (lastAutoRefresh) {
    window.clearTimeout(lastAutoRefresh);
  }
  const accessToken = getAccessToken();
  if (!accessToken || !isValidJwt(accessToken)) {
    return;
  }
  lastAutoRefresh = window.setTimeout(() => {
    lastAutoRefresh = undefined;
    doRefreshToken();
  }, getJwtReminderTime(accessToken));
};

const handler = (ev: StorageEvent) => {
  if (ev.key === 'access_token') {
    observeAccessToken();
  }
};

window.addEventListener('storage', handler);

export const getValidAccessToken = async () => {
  const accessToken = getAccessToken();
  if (accessToken && isValidJwt(accessToken, JWT_EXPIRY_THRESHOLD)) {
    if (!lastAutoRefresh) {
      observeAccessToken();
    }
    return Promise.resolve(accessToken);
  }

  try {
    return await doRefreshToken();
  } catch (error) {
    handleError(error, { displayToast: false });
    logout();
    throw new Error('Error communicating with the authorization system.');
  }
};
