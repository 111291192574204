import type { WorkOrderFormValues } from '@/components/WorkOrderModalStep1/WorkOrderModalStep1';
import type { Address } from '@/domain/address';
import type { PropifyEntity } from '@/domain/base';

export enum JobType {
  TURN = 'TURN',
  REHAB = 'REHAB',
  GENERAL_REMEDIATION = 'GENERAL_REMEDIATION',
  COMPLIANCE = 'COMPLIANCE',
  BUYER_REPAIR = 'BUYER_REPAIR',
  MUNICIPAL_REPAIR = 'MUNICIPAL_REPAIR',
  HOA_REPAIR = 'HOA_REPAIR',
  PARTIAL = 'PARTIAL',
  MOVE_IN = 'MOVE_IN',
}

export enum JobStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  COMPLETED = 'COMPLETED',
}

export interface JobsCount {
  activeJobCount: number;
  openWorkOrderCount: number;
}

export interface Job extends PropifyEntity {
  type: JobType;
  status: JobStatus;
  vendorId: number;
  addressId: number;
  address?: Address;
  propertyId: number;
  unitId: number;
  propertyLot?: string;
}

export interface JobSearchParams {
  keywords?: string;
  id?: number;
  status?: JobStatus | JobStatus[];
  type?: JobType[];
  state?: string | string[];
  allWorkOrdersClosed?: boolean;
  hasPendingBids?: boolean;
  hasOverdueWorkOrders?: boolean;
  hasUnassignedWorkOrders?: boolean;
  hasUnacceptedWorkOrders?: boolean;
  unitId?: number | number[];
  propertyId?: number | number[];
  vendorId?: number;
  userGroupId?: number;
}

export interface JobSearchResult extends Job {
  workOrderTotal: number;
  workOrderCountNew: number;
  workOrderCountAccepted: number;
  workOrderCountScheduled: number;
  workOrderCountPendingBid: number;
  workOrderCountPendingApproval: number;
  workOrderCountWorkComplete: number;
  workOrderCountFinalized: number;
  vendorName: string;
  orgId: number;
  hasOpenWorkOrdersNotAssignedToJobUserGroup: boolean;
  turnRehabId?: number;
}

export interface CreateJobBody {
  status: JobStatus;
  type: string;
  vendorId?: number;
  addressId?: number;
  propertyId?: number;
  unitId?: number;
  workOrder?: WorkOrderFormValues;
  workOrderId?: number;
}
