import type { Enum } from '@/domain/base';

export enum ProcessType {
  MOVE_IN = 'MOVE_IN',
  MOVE_OUT = 'MOVE_OUT',
  TURN_REHAB = 'TURN_REHAB',
  LISTING_PROCESS = 'LISTING_PROCESS',
  UNIT_ONBOARDING = 'UNIT_ONBOARDING',
  LEASING = 'LEASING',
  DISPUTE = 'DISPUTE',
  UNIT_APPLICATION = 'UNIT_APPLICATION',
  RENEWAL = 'RENEWAL',
  DISPOSITION = 'DISPOSITION',
  DISPOSITION_OFFER = 'DISPOSITION_OFFER',
  EVICTION = 'EVICTION',
  COLLECTION = 'COLLECTION',
}

export interface ProcessTypeEnum extends Enum<ProcessType> {
  associatedProcesses: string[];
  hasResponsible: boolean;
  hasTasks: boolean;
}

export interface ProcessAssociation {
  processId: number;
  processType: ProcessType;
}
